import React from "react"

import Layout from "../components/layout"
import Navigation from "../components/navigation"
import SEO from "../components/seo"
import Footer from "../components/footer"

import flowlogo from "../images/logos/flowlogo.png"

const TermsOfUse = () => (
  <Layout>
    <SEO 
      title="Flow - Terms and Conditions" 
      description="Flow goes straight to the source of the largest social platforms in the world - to match people with property" 
      image={flowlogo}
    />
    <Navigation />

    <div className="container mx-auto text-sm mt-10 leading-relaxed">

      <h2 className="font-bold text-lg mt-10 text-xl">
        TERMS OF USE
      </h2>

      <h3 className="font-bold text-lg text-lg mt-5">
        1. INTRODUCTION
      </h3>

      <ol className="list-decimal list-inside text-gray-700 mt-3">
        <li className="mt-2">Flow Living (Pty) Ltd ("Flow” we" or "us") hosts various Platforms under domain name www.Flowliving.com ("the Platform").</li>
        <li className="mt-2">Flow’s core business is to facilitate the matching of people to property and Brands (“Flow Partners”) to people, by creating automated ad’s on Social Platforms on behalf of its Clients using FLOWFUEL.</li>
        <li className="mt-2">Flow’s proprietary technology FLOWFUEL is API integrated into various social and search platforms; amongst others; Facebook, Instagram, Google, resulting in Flow leads emanating thereout directly into Flow’s Platform and automatically pushed to Client’s in real time.</li>
        <li className="mt-2">These Standard Terms of Use include, and must be read together with, Flow’s Privacy Policy available at www.Flowliving.com/privacypolicy.html (collectively "STC's").</li>
        <li className="mt-2">Flow may amend these STC's at any time.</li>
        <li className="mt-2">if user are reading these terms as a result of clicking a Facebook or Instagram ad, which is suggesting the t&c’s are that of an estate agent/landlord or Flow partner, it is because the estate agent/landlord or Flow partner is using the platform in which event these terms apply.</li>
      </ol>

      <h3 className="font-bold text-lg text-lg mt-10">
        2. APPLICATION TO CLIENTS AND USERS
      </h3>

      <ol className="list-decimal list-inside text-gray-700 mt-3">
        <li className="mt-2">For Clients that use FLOWFUEL, these STC's together with the signed agreement ("the Contract") concluded between Flow and each Client shall apply . If there is any discrepancy or conflict between these STC's and the Contract, the provisions of the Contract will apply.</li>
        <li className="mt-2">For other users, by logging in, registering on, accessing or simply using the Platform, all persons making use of the Platform ("Users") agree to be bound by these STC's, except those provisions which, explicitly or implicitly, only apply to Clients.</li>
      </ol>

      <h3 className="font-bold text-lg text-lg mt-10">
        3. FLOW'S GENERAL OBLIGATIONS AND WARRANTIES
      </h3>

      <ol className="list-decimal list-inside text-gray-700 mt-3">
        <li className="mt-2">FLOW must provide the following services to the Clients:
          <ol className="list-decimal list-inside text-gray-700 mt-3 ml-5">
            <li className="mt-2">market Client properties, in terms of the Contract.</li>
            <li className="mt-2">Allow Users to search for properties and engage directly with Estate Agents, Landlords and Flow Partners or as otherwise determined by Flow from time to time.</li>
          </ol>
        </li>
      </ol>

      <h3 className="font-bold text-lg text-lg mt-10">
        4. USE OF THE PLATFORM
      </h3>

      <ol className="list-decimal list-inside text-gray-700 mt-3">
        <li className="mt-2">Each User acknowledges that Flow does not verify or warrant the accuracy or completeness of the property listings or offers by Flow Partners.</li>
        <li className="mt-2">A User who clicks on a FLOWFUEL Ad on any of the related social or search platforms, acknowledges and accepts that they may receive marketing material from Flow and the Estate Agent/Landlord and Flow Partner, as the case may be, and in all instances the User shall have the right to “opt-out” from said communication when it forms part of direct marketing efforts.</li>
        <li className="mt-2">Flow uses "cookies" on the Platform to; amongst others; enable it to personalise each User's visit to the Platform; to personalise each User’s email, sms or the like which the User shall receive in terms of the Flow user journey; simplify the signing-in procedure where relevant; keep track of User preferences and to track the usage thereof. Cookies are small pieces of information that are stored in the hard drive of the User's computer by the browser. The browser will have the option to prevent Platforms using cookies, but this may reduce the functionality of the Platform.</li>
        <li className="mt-2">A function of the Platform is to send a receipt (via email, sms or other) to the User once they have submitted their personal details to an Estate Agent/Landlord wherein Flow places marketing material of a Flow Partner, which can also be found at <a href="www.flowliving.com/partners">www.flowliving.com/partners</a>.</li>
        <li className="mt-2">No User/Client may:
          <ol className="list-decimal list-inside text-gray-700 mt-3 ml-5">
            <li className="mt-2">market Client properties, in terms of the Contract.</li>
            <li className="mt-2">Allow Users to search for properties and engage directly with Estate Agents, Landlords and Flow Partners or as otherwise determined by Flow from time to time.</li>
            <li className="mt-2">use or access any service, information, application or software available via the Platform in a manner not expressly permitted Flow; nor</li>
            <li className="mt-2">input or upload to the Platform any information which contains viruses, Trojan horses, worms, time bombs or other computer programming routines that are intended to damage, interfere with, intercept or expropriate any system or the Platform nor</li>
            <li className="mt-2">upload any Personal Information or other data that infringes the intellectual property rights or privacy rights of another; nor</li>
            <li className="mt-2">link any page on the Platform without Flow's prior written consent. The Platform contains links to other third party websites over which Flow has no control. Flow does not recommend any product or service advertised on those websites and the User uses them at its own risk.</li>
          </ol>
        </li>
      </ol>

      <h3 className="font-bold text-lg text-lg mt-10">
        5. INDEMNITIES AND WAIVERS
      </h3>

      <ol className="list-decimal list-inside text-gray-700 mt-3">
        <li className="mt-2">Each Client and User hereby acknowledges that:</li>
        <li className="mt-2">
          <ol className="list-decimal list-inside text-gray-700 mt-3 ml-5">
            <li className="mt-2">Flow is not an Estate Agent, Landlord, Partner, attorney, financial adviser or other professional adviser in relation to any property transaction;</li>
            <li className="mt-2">each User is responsible for the negotiation and conclusion of any agreements that may result from the Ad listing’son the Platform or any engagement with a Flow Partner </li>
            <li className="mt-2">there is no obligation on a Client/User to make use of any of the Flow Partners on the Platform;</li>
            <li className="mt-2">Flow does not regulate or take any responsibility for the content, accuracy or completeness of the Listings, the Client's/User's information or for the security of the Client's/User's passwords in respect of the Platform.</li>
          </ol>
        </li>
      </ol>

      <h3 className="font-bold text-lg text-lg mt-10">
        6. WAIVER
      </h3>

      <ol className="list-decimal list-inside text-gray-700 mt-3">
        <li className="mt-2">Each User and Client hereby waives any rights or claims it may have against Flow in respect of any loss, liability, damage (whether direct or consequential) or expense of any nature whatsoever, which may be suffered as a result of the User's or Client's use or inability to use the Platform or the services or content provided from and through the Platform.</li>
      </ol>

      <h3 className="font-bold text-lg text-lg mt-10">
        7. CONFIDENTIALITY
      </h3>

      <ol className="list-decimal list-inside text-gray-700 mt-3">
        <li className="mt-2">Flow undertakes to:</li>
        <li className="mt-2">
          <ol className="list-decimal list-inside text-gray-700 mt-3 ml-5">
            <li className="mt-2">keep confidential all Client and User information;</li>
            <li className="mt-2">not disclose the Information to any Estate Agent/Landlord of Flow Partner unless the User has elected to do so on their own accord via the Platform;</li>
            <li className="mt-2">use the Information solely in connection with the implementation of the Platform.</li>
          </ol>
        </li>
      </ol>

      <h3 className="font-bold text-lg text-lg mt-10">
        8. INTELLECTUAL PROPERTY INDEMNITIES
      </h3>

      <ol className="list-decimal list-inside text-gray-700 mt-3">
        <li className="mt-2">All the content on the Platform, including any and all graphics, text, icons, photographs, videos, hyperlinks, private information, designs, trademarks, software, databases, agreements, the name "Flow and Flowliving" and the associated logo (which are both registered trademarks) the Platform source code, copyrights and any modifications and/or upgrades thereto, is the intellectual property of Flow.</li>
        <li className="mt-2">The Clients and Users may not reverse-engineer or attempt to copy the Platform in any way. The Client and Users may not use any automatic device or manual process to monitor or copy any part of the Platform.</li>
      </ol>

      <h3 className="font-bold text-lg text-lg mt-10">
        9. DISPUTES
      </h3>

      <ol className="list-decimal list-inside text-gray-700 mt-3">
        <li className="mt-2">Either party may refer any dispute which arises out of the use of this Platform to the other party for resolution and the parties must meet within 10 Days thereof to resolve the matter or to agree on alternate dispute proceedings, failing which either party may take any action appropriate. If the parties elect arbitration, the arbitration must be in Durban, before a single arbitrator. This clause does not prevent either party from applying to any court having jurisdiction for appropriate urgent relief.</li>
        <li className="mt-2">South African law governs the interpretation of these T&C’s.</li>
      </ol>

    </div>

    <Footer />
  </Layout>
)

export default TermsOfUse
